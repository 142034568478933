import { getCookie, setCookie } from '@/fsd/shared/utils';

export default {
    namespaced: true,
    state: {
        isShow: true,
        cookieSettings: {
            necessary: true,
            preferences: true,
            statistics: true,
        },
    },
    getters: {
        isShowCookieBanner(state) {
            return !getCookie('COOKIE_AGREE') && state.isShow;
        },
    },
    mutations: {
        DEFAULT_MUTATION(state, payload) {
            state[payload.property] = payload.value;
        },
        SET_IS_SHOW(state) {
            state.isShow = false;
            setCookie('COOKIE_AGREE', 'y', {
                'max-age': 2592000,
            });
        },
        SET_COOKIE_SETTINGS(state, cookieSettings) {
            state.cookieSettings = cookieSettings;
            Object.keys(state.cookieSettings).forEach((key) => {
                setCookie(
                    `COOKIE_SETTINGS_${key.toUpperCase()}`,
                    state.cookieSettings[key] ? 'y' : 'n',
                    {
                        'max-age': 2592000,
                    }
                );
            });
        },
        SET_COOKIE_SETTINGS_VALUE(state, { property, value }) {
            state.cookieSettings[property] = value;
            setCookie(`COOKIE_SETTINGS_${property.toUpperCase()}`, value ? 'y' : 'n', {
                'max-age': 2592000,
            });
        },
    },
    actions: {
        initCookieSettings({ state, commit }) {
            const localCookieSettings = JSON.parse(JSON.stringify(state.cookieSettings));

            Object.keys(localCookieSettings).forEach((key) => {
                const cookieValue = getCookie(`COOKIE_SETTINGS_${key.toUpperCase()}`);

                if (cookieValue) {
                    localCookieSettings[key] = cookieValue === 'y' ? true : false;
                }
            });

            commit('DEFAULT_MUTATION', {
                property: 'cookieSettings',
                value: localCookieSettings,
            });
        },
    },
};
