import { Payment } from '@/fsd/shared/api/students/proto';

export const getPaymentInfoKey = (type, number) => {
    let localType = '';

    switch (type) {
        case 'notificaitonpreference':
            localType = 'NotificationPreference';
            break;
        case 'valute':
            localType = 'PaymentValute';
            break;
        default:
            break;
    }

    return (
        Object.keys(Payment[localType]).find((key) => Payment[localType][key] === number) ||
        'UNKOWN'
    );
};
