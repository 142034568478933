import { getCookie, getTimestampDate, getTimestamp } from '@/fsd/shared/utils';
import { useBaseApi } from '../base';
import {
    StudentsPromiseClient,
    SchedulePromiseClient,
    PaymentsPromiseClient,
    AddStudentRequest,
    GetFullProfileRequest,
    GetProfileListRequest,
    EditStudentRequest,
    AddLessonRequest,
    EditLessonRequest,
    GetLessonListRequest,
    GetLessonListByIdsRequest,
    GetPaymentsByIdListRequest,
    EditPaymentRequest,
    Anchor,
    LessonAnchor,
    ProfileInfoByCourseRequest,
    RemoveLessonRequest,
} from './proto';

export const useStudentsApi = () => {
    const url = process.env.API_URL;
    const mediaUrl = 'https://api.petroka.app/static/upload?withBlurhash=true';
    const credentials = { session: getCookie('session') };

    const { promiseClient: studentsPromiseClient, googleEmpty: studentsGoogleEmpty } = useBaseApi(
        { PromiseClient: StudentsPromiseClient },
        { url }
    );
    const { promiseClient: schedulePromiseClient, googleEmpty: scheduleGoogleEmpty } = useBaseApi(
        { PromiseClient: SchedulePromiseClient },
        { url }
    );
    const { promiseClient: paymentsPromiseClient } = useBaseApi(
        { PromiseClient: PaymentsPromiseClient },
        { url }
    );

    const getStudentsList = () =>
        studentsPromiseClient.getStudentList(studentsGoogleEmpty, credentials);

    const addStudent = ({ uuid, firstName, lastName, photo, contacts }) => {
        const request = new AddStudentRequest();

        request.setIdempotencykey(uuid);
        request.setFirstname(firstName);
        request.setLastname(lastName);
        request.setPhotourl(photo);
        request.setContactlistList(contacts);

        return studentsPromiseClient.addStudent(request, credentials);
    };

    const getStudentById = ({ id }) => {
        const request = new GetFullProfileRequest();

        request.setId(id);

        return studentsPromiseClient.getFullProfile(request, credentials);
    };

    const getStudentsByIds = ({ ids }) => {
        const request = new GetProfileListRequest();

        request.setIdlistList(ids);

        return studentsPromiseClient.getProfileListByIds(request, credentials);
    };

    const editStudent = ({ id, firstName, lastName, photo, contacts }) => {
        const request = new EditStudentRequest();

        request.setId(id);
        request.setFirstname(firstName);
        request.setLastname(lastName);
        request.setPhotourl(photo);
        request.setContactlistList(contacts);

        return studentsPromiseClient.editStudent(request, credentials);
    };

    const uploadAvatar = ({ data }) =>
        fetch(mediaUrl, {
            headers: credentials,
            method: 'POST',
            body: data,
        });

    const getLessonsIdsList = ({ studentId, limit, requestOptions = null }) => {
        const request = new GetLessonListRequest();
        const anchor = new Anchor();

        if (!requestOptions) {
            anchor.setFromscratch(scheduleGoogleEmpty);
        } else {
            const lessonAnchor = new LessonAnchor();

            lessonAnchor.setCreatedat(getTimestamp(requestOptions.createAt));
            lessonAnchor.setStartdate(getTimestamp(requestOptions.startDate));
            anchor.setFromanchor(lessonAnchor);
        }

        request.setStudentid(studentId);
        request.setAnchor(anchor);
        request.setLimit(limit);

        return schedulePromiseClient.getLessonList(request, credentials);
    };

    const getLessonsByIds = ({ ids }) => {
        const request = new GetLessonListByIdsRequest();

        request.setIdlistList(ids);

        return schedulePromiseClient.getLessonListByIds(request, credentials);
    };

    const addLesson = ({
        studentId,
        uuid,
        location,
        startDate,
        endDate,
        duration,
        paymentInfo,
    }) => {
        const request = new AddLessonRequest();
        const startDateTimestamp = getTimestampDate(startDate);
        const endDateTimestamp = getTimestampDate(endDate);

        request.setStudentid(studentId);
        request.setIdempotencykey(uuid);
        request.setLocation(location);
        request.setStartDate(startDateTimestamp);
        request.setEndDate(endDateTimestamp);
        request.setReminderduration(duration);
        request.setPaymentinfo(paymentInfo);

        return schedulePromiseClient.addLesson(request, credentials);
    };

    const editLesson = ({ lessonId, location, startDate, endDate, duration }) => {
        const request = new EditLessonRequest();
        const startDateTimestamp = getTimestampDate(startDate);
        const endDateTimestamp = getTimestampDate(endDate);

        request.setId(lessonId);
        request.setLocation(location);
        request.setStartDate(startDateTimestamp);
        request.setEndDate(endDateTimestamp);
        request.setReminderduration(duration);

        return schedulePromiseClient.editLesson(request, credentials);
    };

    const editPayment = ({ id, cost, valute, notificationPreference }) => {
        const request = new EditPaymentRequest();

        request.setId(id);
        request.setCost(cost);
        request.setNotificationpreference(notificationPreference);
        request.setValute(valute);

        return paymentsPromiseClient.editPayment(request, credentials);
    };

    const getPaymentByIds = ({ ids }) => {
        const request = new GetPaymentsByIdListRequest();

        request.setIdlistList(ids);

        return paymentsPromiseClient.getPaymentsByIdList(request, credentials);
    };

    const getStudentByCourseId = ({ id }) => {
        const request = new ProfileInfoByCourseRequest();

        request.setCourseid(id);

        return studentsPromiseClient.profileInfoByCourseID(request, credentials);
    };

    const removeLesson = ({ id }) => {
        const request = new RemoveLessonRequest();

        request.setId(id);

        return schedulePromiseClient.removeLesson(request, credentials);
    };

    return {
        getStudentsList,
        getStudentsByIds,
        addStudent,
        getStudentById,
        editStudent,
        uploadAvatar,
        addLesson,
        editLesson,
        getLessonsIdsList,
        getLessonsByIds,
        getPaymentByIds,
        editPayment,
        getStudentByCourseId,
        removeLesson,
    };
};
