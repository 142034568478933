import { createRouter, createWebHistory } from 'vue-router';
import { store } from '../store';
import { showNotify } from '@/fsd/shared/utils';
import { getCookie, setCookie } from '@/fsd/shared/utils';
import { useAuthorizationApi } from '@/fsd/shared/api/authorization';

const routes = [
    // {
    //     path: '/',
    //     redirect: { name: 'Students' },
    // },
    // {
    //     path: '/students',
    //     name: 'Students',
    //     meta: {
    //         requiresAuth: true,
    //     },
    //     component: () => import('@/views/Students'),
    // },
    // {
    //     path: '/students/:id',
    //     name: 'Student detail',
    //     meta: {
    //         requiresAuth: true,
    //     },
    //     props: true,
    //     component: () => import('@/views/StudentDetail'),
    // },
    // {
    //     path: '/courses/:id',
    //     name: 'Courses',
    //     meta: {
    //         requiresAuth: true,
    //     },
    //     props: true,
    //     component: () => import('@/views/Courses'),
    // },
    // {
    //     path: '/schedule',
    //     name: 'Schedule',
    //     meta: {
    //         requiresAuth: true,
    //     },
    //     component: () => import('@/views/Schedule'),
    // },
    // {
    //     path: '/payment',
    //     name: 'Payment',
    //     meta: {
    //         requiresAuth: true,
    //     },
    //     component: () => import('@/views/Payment'),
    // },
    // {
    //     path: '/settings',
    //     name: 'Settings',
    //     meta: {
    //         requiresAuth: true,
    //     },
    //     component: () => import('@/views/Settings'),
    // },
    {
        path: '/',
        redirect: { name: 'Login' },
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/fsd/pages/Authorization'),
    },
    // {
    //     path: '/registration',
    //     name: 'Registration',
    //     component: () => import('@/views/Registration'),
    // },
    // {
    //     path: '/registration/confirm',
    //     name: 'Confirm',
    //     props: true,
    //     component: () => import('@/views/Confirm'),
    // },
    // {
    //     path: '/send-code',
    //     name: 'ResetPasswordSend',
    //     component: () => import('@/views/ResetPasswordSend'),
    // },
    // {
    //     path: '/send-code/change-password',
    //     name: 'ResetPasswordEnter',
    //     props: true,
    //     component: () => import('@/views/ResetPasswordEnter'),
    // },
    // {
    //     path: '/invoice/success',
    //     name: 'InvoiceSuccess',
    //     component: () => import('@/views/InvoiceSuccess'),
    // },
    // {
    //     path: '/invoice/:id',
    //     name: 'Invoice',
    //     component: () => import('@/views/Invoice'),
    // },
    // {
    //     path: '/privacy',
    //     name: 'Privacy',
    //     component: () => import('@/views/Privacy'),
    // },
    // {
    //     path: '/follow',
    //     name: 'Follow',
    //     component: () => import('@/views/Follow.vue'),
    // },
    // {
    //     path: '/cookie-settings',
    //     name: 'CookieSettings',
    //     component: () => import('@/views/CookieSettings.vue'),
    // },
    // {
    //     path: '/oauth',
    //     name: 'OAuthLogin',
    //     redirect: (to) => {
    //         return `/students?session=${to.query.session || ''}`;
    //     },
    // },
    // {
    //     path: '/oauth/web/apple|fb|google/init',
    //     name: 'OAuth',
    //     beforeEnter(to, from, next) {
    //         window.location.href = `${process.env.OAUTH_URL}${to.fullPath}`;
    //     },
    // },
    // {
    //     path: '/:pathMatch(.*)*',
    //     name: 'NotFound',
    //     component: () => import('@/views/NotFound'),
    // },
    // {
    //     path: '/:pathMatch(.*)',
    //     name: 'BadNotFound',
    //     component: () => import('@/views/NotFound'),
    // },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    linkActiveClass: 'active',
    linkExactActiveClass: 'active',
});

router.beforeEach(async (to, from, next) => {
    const { verifyLogin } = useAuthorizationApi();
    const session = getCookie('session') || to.query.session;

    if (to.query.session) {
        setCookie('session', session);
    }

    if (to.name === 'Invoice' || to.name === 'InvoiceSuccess') {
        store.commit('DEFAULT_MUT', {
            property: 'isInvoice',
            value: true,
        });
    } else {
        if (store.state.isInvoice) {
            store.commit('DEFAULT_MUT', {
                property: 'isInvoice',
                value: false,
            });
        }
    }

    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (session && store.state.isAuth) {
            next();
        } else if (session && !store.state.isAuth) {
            try {
                const response = await verifyLogin(session);

                store.commit('DEFAULT_MUT', {
                    property: 'isAuth',
                    value: true,
                });
                store.commit('DEFAULT_MUT', {
                    property: 'user',
                    value: response.toObject().user,
                });
                next();
            } catch (error) {
                showNotify(error)();
            }
        } else {
            next({ name: 'Login' });
        }
    } else {
        next();
    }
});

export { router };
