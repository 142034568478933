<template>
    <div class="beta-block">
        <span class="beta-block__text">BETA</span>
    </div>
</template>

<style lang="scss" scoped>
    .beta-block {
        padding: 0.4rem;
        border-radius: 0.4rem;
        background: $badgeGradient;
        line-height: 0;

        &__text {
            font-size: 1rem;
            line-height: 1.2rem;
            font-weight: 400;
            letter-spacing: 0.12px;
            color: $white;
            text-transform: uppercase;
        }
    }
</style>
