export const getCookie = (name) => {
    const matches = document.cookie.match(
        new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)')
    );

    return matches ? decodeURIComponent(matches[1]) : undefined;
};

export const setCookie = (name, value, options = {}) => {
    const internalOptions = {
        path: '/',
        ...options,
    };
    let updatedCookie = encodeURIComponent(name) + '=' + encodeURIComponent(value);

    if (internalOptions.expires instanceof Date) {
        internalOptions.expires = internalOptions.expires.toUTCString();
    }

    for (let optionKey in options) {
        const optionValue = options[optionKey];

        updatedCookie += '; ' + optionKey;

        if (optionValue !== true) {
            updatedCookie += '=' + optionValue;
        }
    }

    document.cookie = updatedCookie;
};

export const deleteCookie = (name) => {
    setCookie(name, '', {
        'max-age': -1,
    });
};
