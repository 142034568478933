<template>
    <inline-svg
        :src="iconPath"
        :title="title"
    />
</template>

<script setup>
    import { computed } from 'vue';
    import InlineSvg from 'vue-inline-svg';

    const props = defineProps({
        iconName: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            default: '',
        },
    });

    const iconPath = computed(() => require(`@/assets/icons/${props.iconName}.svg`));
</script>

<style lang="scss" scoped></style>
