import { getTimestampDate, getCookie } from '@/fsd/shared/utils';
import { SchedulePromiseClient, GetScheduleRequest } from '../students/proto';
import { useBaseApi } from '../base';
import { CalendarPromiseClient } from './proto';

export const useScheduleApi = () => {
    const url = process.env.API_URL;
    const credentials = { session: getCookie('session') };

    const { promiseClient: schedulePromiseClient } = useBaseApi(
        { PromiseClient: SchedulePromiseClient },
        { url }
    );
    const { promiseClient: calendarPromiseClient, googleEmpty } = useBaseApi(
        { PromiseClient: CalendarPromiseClient },
        { url }
    );

    const getSchedule = ({ startDate, endDate }) => {
        const request = new GetScheduleRequest();

        request.setStartdate(getTimestampDate(startDate));
        request.setEnddate(getTimestampDate(endDate));

        return schedulePromiseClient.getSchedule(request, credentials);
    };

    const getCalendarInfo = () => calendarPromiseClient.getCalendarInfo(googleEmpty, credentials);

    const enableSharingCalendar = () =>
        calendarPromiseClient.enableCalendar(googleEmpty, credentials);

    return { getSchedule, getCalendarInfo, enableSharingCalendar };
};
