import dayjs from 'dayjs';
import { event } from 'vue-gtag';
import { useStudentsApi } from '@/fsd/shared/api/students';
import { useScheduleApi } from '@/fsd/shared/api/schedule';
import { getDateFromTimestamp } from '@/fsd/shared/utils';
import { showNotify } from '@/fsd/shared/utils';
import { Payment } from '@/fsd/shared/api/students/proto';

const {
    getStudentsList,
    getStudentById,
    getLessonsIdsList,
    getLessonsByIds,
    getPaymentByIds,
    getStudentsByIds,
    removeLesson,
} = useStudentsApi();

const { getSchedule, getCalendarInfo } = useScheduleApi();

export default {
    namespaced: true,
    state: {
        students: [],
        schedule: [],
        currentScheduleDate: new Date(),
        currentStudent: null,
        currentStudentLessons: null,
        currentLesson: null,
        shouldPromote: false,
        calendarId: '',
        isLoading: false,
        needUpdStudents: true,
    },
    getters: {
        getSortedStudentList: (state) => (sortType) => {
            const copyStudents = JSON.parse(JSON.stringify(state.students));

            switch (sortType) {
                case 'NAME':
                    return copyStudents.sort((a, b) => {
                        const nameOne = a.firstname.toLowerCase();
                        const nameTwo = b.firstname.toLowerCase();

                        if (nameOne < nameTwo) return -1;
                        if (nameOne > nameTwo) return 1;
                        return 0;
                    });
                case 'DATE':
                    return copyStudents.sort((a, b) => {
                        if (a.lesson.startDate && b.lesson.startDate) {
                            const startDateOne = getDateFromTimestamp(a.lesson.startDate);
                            const startDateTwo = getDateFromTimestamp(b.lesson.startDate);

                            return startDateOne - startDateTwo;
                        } else {
                            if (!a.lesson.startDate) return -1;
                            if (!b.lesson.startDate) return 1;

                            return 0;
                        }
                    });
                default:
                    return state.students;
            }
        },
        getSortedSchedule: (state) => (sortType) => {
            const copySchedule = JSON.parse(JSON.stringify(state.schedule));

            switch (sortType) {
                case 'NAME':
                    return copySchedule.sort((a, b) => {
                        const nameOne = a.student.firstname.toLowerCase();
                        const nameTwo = b.student.firstname.toLowerCase();

                        if (nameOne < nameTwo) return -1;
                        if (nameOne > nameTwo) return 1;
                        return 0;
                    });
                case 'DATE':
                    return copySchedule.sort((a, b) => {
                        const startDateOne = getDateFromTimestamp(a.startdate);
                        const startDateTwo = getDateFromTimestamp(b.startdate);

                        return startDateOne - startDateTwo;
                    });
                default:
                    return state.schedule;
            }
        },
    },
    mutations: {
        defaultMutation(state, { value, property }) {
            state[property] = value;
        },
        addStudentToState(state, student) {
            state.students.push(student);
        },
        setStudents(state, students) {
            state.students = students;
        },
        setScheduleList(state, schedule) {
            state.schedule = schedule;
        },
        setCurrentStudent(state, student) {
            state.currentStudent = student;
        },
        setCurrentScheduleDate(state, date) {
            state.currentScheduleDate = date;
        },
        editStudentInState(state, student) {
            const currentStudent = state.students.find((obj) => obj.id === student.id);

            if (currentStudent) {
                for (let key in student) {
                    if (currentStudent[key] !== student[key]) currentStudent[key] = student[key];
                }
            }
        },
        setCurrentStudentLessons(state, lessons) {
            state.currentStudentLessons = lessons;
        },
        setCurrentLesson(state, lesson) {
            state.currentLesson = lesson;

            if (lesson) {
                event('open_lesson_detail', {
                    event_category: 'lesson detail',
                    event_label: 'user open lesson detail of see it',
                });
            } else {
                event(`close_lesson_detail`, {
                    event_category: 'lesson detail',
                    event_label: 'user close lesson detail',
                });
            }
        },
        addLessonToState(state, { lesson, type }) {
            if (type === 'Students') {
                if (state.currentStudentLessons) {
                    state.currentStudentLessons.push(lesson);
                } else {
                    state.currentStudentLessons = [lesson];
                }
            } else {
                const currentStartDate = getDateFromTimestamp(lesson.startdate);

                if (dayjs(state.currentScheduleDate).diff(currentStartDate, 'day') === 0) {
                    state.schedule.push(lesson);
                }
            }
        },
        deleteLessonInState(state, { id, type }) {
            if (type === 'schedule') {
                const index = state.schedule.findIndex((lesson) => lesson.id === id);

                if (index !== -1) {
                    state.schedule.splice(index, 1);
                }
            }

            if (type === 'student_detail') {
                const index = state.currentStudentLessons.findIndex((lesson) => lesson.id === id);

                if (index !== -1) {
                    state.currentStudentLessons.splice(index, 1);
                }
            }
        },
        editLessonInState(state, { lesson, type }) {
            let currentLesson = null;
            let prevStartDate = null;

            if (type === 'Students') {
                currentLesson = state.currentStudentLessons.find((obj) => obj.id === lesson.id);
            } else {
                currentLesson = state.schedule.find((obj) => obj.id === lesson.id);
                prevStartDate = JSON.parse(JSON.stringify(currentLesson.startdate));
            }

            for (let key in currentLesson) {
                if (
                    JSON.stringify(currentLesson[key]) !== JSON.stringify(lesson[key]) &&
                    lesson.hasOwnProperty(key)
                ) {
                    if (key === 'startdate' || key === 'enddate') {
                        const propertyName = key === 'startdate' ? 'startDateText' : 'endDateText';
                        const format = key === 'startdate' ? 'MMM D, ddd, HH:mm A' : 'HH:mm A';
                        state.currentLesson[propertyName] = dayjs(
                            getDateFromTimestamp(lesson[key])
                        ).format(format);
                    }
                    state.currentLesson[key] = lesson[key];
                    currentLesson[key] = lesson[key];
                }
            }

            if (type === 'Schedule') {
                if (JSON.stringify(currentLesson.startdate) !== JSON.stringify(prevStartDate)) {
                    const index = state.schedule.findIndex((obj) => obj.id === lesson.id);
                    state.schedule.splice(index, 1);
                }
            }
        },
        editLessonPaymentInState(state, { payment, type }) {
            const paymentStatuses = Payment.PaymentStatus;
            const paymentValute = Payment.PaymentValute;
            const notificationStatuses = Payment.NotificationPreference;
            const localPaymentValute = {
                UNKNOWN_VALUTE: '-',
                US_DOLLAR: '$',
                EURO: '€',
                UK_POUNDS: '£',
            };
            const localPaymentStatuses = {
                AWAITS_CONFIRMATION: 'Awaits',
                COMPLETED: 'Completed',
                IN_PROGRESS: 'In_progress',
                PENDING: 'Pending',
                UNKNOWN_STATUS: '-',
            };
            let currentPayment = null;

            if (type === 'Students') {
                currentPayment = state.currentStudentLessons.find(
                    (obj) => obj.payment.id === payment.id
                ).payment;
            } else {
                currentPayment = state.schedule.find(
                    (obj) => obj.payment.id === payment.id
                ).payment;
            }

            for (let key in currentPayment) {
                if (
                    JSON.stringify(currentPayment[key]) !==
                    JSON.stringify(payment[key] && payment[key])
                ) {
                    if (key === 'notificaitonpreference') {
                        state.currentLesson.payment.notificationPreferenceText = Object.keys(
                            notificationStatuses
                        )
                            .find(
                                (key) =>
                                    notificationStatuses[key] === payment.notificaitonpreference
                            )
                            .toLowerCase();
                    }

                    if (key === 'paymentstatus') {
                        state.currentLesson.payment.paymentStatusText =
                            localPaymentStatuses[
                                Object.keys(paymentStatuses).find(
                                    (key) => paymentStatuses[key] === payment.paymentstatus
                                )
                            ];
                    }

                    if (key === 'valute') {
                        state.currentLesson.payment.valuteText =
                            localPaymentValute[
                                Object.keys(paymentValute).find(
                                    (key) => paymentValute[key] === payment.valute
                                )
                            ];
                    }
                    state.currentLesson.payment[key] = payment[key];
                    currentPayment[key] = payment[key];
                }
            }
        },
        setIsLoading(state, flag) {
            state.isLoading = flag;
        },
        setShouldPromote(state, flag) {
            state.shouldPromote = flag;
        },
        setCalendarId(state, id) {
            state.calendarId = id;
        },
        clearState(state) {
            state.students = [];
            state.schedule = [];
            state.currentScheduleDate = new Date();
            state.shouldPromote = false;
            state.needUpdStudents = true;
            state.calendarId = '';
        },
    },
    actions: {
        async getStudentsList(context) {
            if (context.state.needUpdStudents) {
                context.commit('setIsLoading', true);

                try {
                    const response = await getStudentsList();
                    const studentsList = response.getStudentlistList();

                    context.commit('setStudents', []);
                    if (studentsList.length > 0) {
                        studentsList.forEach((student) => {
                            context.commit('addStudentToState', {
                                ...student.toObject().profile,
                                lesson: {
                                    startDate: student.toObject().info?.lesson.startdate,
                                    endDate: student.toObject().info?.lesson.enddate,
                                },
                                paymentStatus: student.toObject().info?.payment.paymentstatus,
                            });
                        });
                    }
                    context.commit('defaultMutation', {
                        property: 'needUpdStudents',
                        value: false,
                    });
                } catch (error) {
                    showNotify(error)();
                } finally {
                    context.commit('setIsLoading', false);
                }
            }
        },
        async getStudentDetail(context, payload) {
            try {
                const response = await getStudentById(payload.id);

                if (!payload.getCourseId) {
                    context.commit('setCurrentStudent', response.toObject());
                } else {
                    return response.toObject().courseid;
                }
            } catch (error) {
                showNotify(error)();
            }
        },
        async getStudentLessons(context, payload) {
            try {
                const responseIds = await getLessonsIdsList(
                    payload.id,
                    payload.limit,
                    payload.requestOptions
                );
                const lessonsIds = responseIds.toObject().lessonlistList.map((obj) => obj.id);
                const paymentIds = responseIds.toObject().paymentlistList.map((obj) => obj.id);
                const responseLessons = await getLessonsByIds(lessonsIds);
                const responsePayments = await getPaymentByIds(paymentIds);
                const resultArr = responseLessons.toObject().lessonlistList.map((lesson) => {
                    const lessonPayment = responsePayments
                        .toObject()
                        .paymentlistList.find((payment) => payment.lessonid === lesson.id);
                    return lessonPayment
                        ? { ...lesson, payment: { ...lessonPayment } }
                        : { ...lesson, payment: null };
                });

                if (payload.requestOptions) {
                    const copyLessons = JSON.parse(
                        JSON.stringify(context.state.currentStudentLessons)
                    );
                    copyLessons.push(...resultArr);
                    context.commit('setCurrentStudentLessons', copyLessons);
                } else {
                    context.commit('setCurrentStudentLessons', resultArr);
                }
            } catch (error) {
                showNotify(error)();
            }
        },
        async getScheduleList(context, payload) {
            context.commit('setIsLoading', true);

            try {
                const response = await getSchedule(payload.startDate, payload.endDate);
                const studentsIds = [
                    ...new Set(response.toObject().profilelistList.map((obj) => obj.id)),
                ];
                const lessonsIds = response.toObject().lessonlistList.map((obj) => obj.id);
                const paymentIds = response.toObject().paymentlistList.map((obj) => obj.id);
                const studentsResponse = await getStudentsByIds(studentsIds);
                const lessonsResponse = await getLessonsByIds(lessonsIds);
                const paymentsResponse = await getPaymentByIds(paymentIds);
                const result = [];

                lessonsResponse.toObject().lessonlistList.forEach((lesson) => {
                    let resultLesson = null;
                    const student = studentsResponse
                        .toObject()
                        .profileList.find((student) => student.id === lesson.studentid);
                    const payment = paymentsResponse
                        .toObject()
                        .paymentlistList.find((payment) => payment.lessonid === lesson.id);

                    resultLesson = {
                        ...lesson,
                        student: {
                            id: student.id,
                            firstname: student.firstname,
                            lastname: student.lastname,
                            photourl: student.photourl,
                        },
                        payment,
                    };

                    result.push(resultLesson);
                });

                context.commit('setScheduleList', []);
                if (result.length > 0) {
                    context.commit('setScheduleList', result);
                }
            } catch (error) {
                console.log(error);
                showNotify(error)();
            } finally {
                context.commit('setIsLoading', false);
            }
        },
        async getCalendarId(context) {
            try {
                const response = await getCalendarInfo();

                context.commit('setShouldPromote', response.toObject().shouldpromote);
                context.commit('setCalendarId', response.toObject().calendarid);
            } catch (error) {
                showNotify(error)();
            }
        },
        async deleteLesson(context, { id, type }) {
            try {
                await removeLesson(id);
                context.commit('setCurrentLesson', null);
                context.commit('deleteLessonInState', { id, type });
                context.commit('defaultMutation', {
                    property: 'needUpdStudents',
                    value: true,
                });
            } catch (error) {
                showNotify(error)();
            }
        },
    },
};
