import { getCookie } from '@/fsd/shared/utils';
import { useBaseApi } from '../base';
import {
    PaymentsPromiseClient,
    PublicPaymentsPromiseClient,
    GetAccountConnectionLinkRequest,
    PaymentCheckoutInfoRequest,
} from './proto';

export const usePaymentsApi = () => {
    const url = process.env.API_URL;
    const credentials = { session: getCookie('session') };

    const { promiseClient: paymentsPromiseClient, googleEmpty } = useBaseApi(
        { PromiseClient: PaymentsPromiseClient },
        { url }
    );
    const { promiseClient: publicPaymentsPromiseClient } = useBaseApi(
        { PromiseClient: PublicPaymentsPromiseClient },
        { url }
    );

    const getPaymentStatus = () => paymentsPromiseClient.getPaymentStatus(googleEmpty, credentials);

    const getAccountConnectionLink = () => {
        const request = new GetAccountConnectionLinkRequest();

        request.setPlatform(2);

        return paymentsPromiseClient.getAccountConnectionLink(request, credentials);
    };

    const getPaymentCheckoutInfo = (paymentId) => {
        const request = new PaymentCheckoutInfoRequest();
        const checkoutId = getCookie(paymentId);

        if (checkoutId) {
            request.setCheckoutid(checkoutId);
        }

        request.setPaymentid(paymentId);

        return publicPaymentsPromiseClient.paymentCheckoutInfo(request, {});
    };

    const payOutNow = () => paymentsPromiseClient.payoutAvailableAmount(googleEmpty, credentials);

    const getDashboardLink = () => paymentsPromiseClient.getDashboardLink(googleEmpty, credentials);

    return {
        getPaymentStatus,
        getAccountConnectionLink,
        getPaymentCheckoutInfo,
        payOutNow,
        getDashboardLink,
    };
};
