import { getTimestampDate } from './date';

const valutesMinLength = {
    $: 50,
    '€': 50,
    '£': 30,
};

export const validDate = ({ date }) => !Number.isNaN(Date.parse(date));

export const startNotEarlier = ({ startDate, endDate }) => {
    return getTimestampDate(endDate).getSeconds() - getTimestampDate(startDate).getSeconds() > 0;
};

export const digitsAfterDot = (number) => {
    if (String(number).indexOf('.') !== -1) {
        return String(number).split('.')[1] && String(number).split('.')[1].length <= 2;
    }
    return true;
};

export const onlyDigitsAndDot = (value) => {
    return RegExp(/^[0-9.]*$/, 'g').test(value);
};

export const costLimit = (value, valuteSymbol) => {
    const cost = Number(value) * 100;
    return cost >= valutesMinLength[valuteSymbol] && cost <= 999999999;
};

export const validations = () => ({
    email: (field) => {
        const regExp = new RegExp(/^[^@]+@[\w\-]+(\.\w+)+\w$/);
        const error = 'Please enter correct email';

        return regExp.test(field.value) || !field.value.trim() || error;
    },
    phone: (field) => {
        const regExp = new RegExp(/^\+(?:[0-9] ?){10,14}[0-9]$/);
        const error = 'Please enter correct phone';

        return regExp.test(field.value) || !field.value || error;
    },
    password: (field) => {
        const regExp = new RegExp();
        const error = 'Password must consist of 6 symbols or more';

        return field.value.trim().length >= 6 || error;
    },
    confirmPassword: (field) => {
        const error = 'Passwords must match';

        return field.value === field.valueConfirm || error;
    },
    confirm: (field) => {
        const regExp = new RegExp(/\d{6}/);
        const error = 'Please enter valid confirm code';

        return regExp.test(field.value) || error;
    },
    startDate: (field) => {
        const error = 'Plesase enter valid date';
        return validDate({ date: field.value }) || error;
    },
    endDate: (field) => {
        const error = 'The end date must be valid and start no earlier than the start date';
        return (
            (validDate({ date: field.value }) &&
                startNotEarlier({ startDate: field.startDateValue, endDate: field.value })) ||
            error
        );
    },
    cost: (field) => {
        const error = `The cost of the lesson should be in the interval between ${
            valutesMinLength[field.prefix] / 100
        }${field.prefix} - 9999999.99${field.prefix}`;
        return (
            (onlyDigitsAndDot(field.value) &&
                digitsAfterDot(field.value) &&
                costLimit(field.value, field.prefix)) ||
            error
        );
    },
    required: (field) => {
        const error = `${field.name} is required`;

        return String(field.value).trim().length > 0 || error;
    },
});
