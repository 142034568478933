<template>
    <authorization v-if="!isAuth || isInvoice"></authorization>
    <!-- <main-layout v-else></main-layout> -->
    <notification />
    <!-- <cookie-banner @setCookie="enableStatistic" /> -->
</template>

<script>
    import { Authorization } from '@/fsd/shared/layouts';
    // import MainLayout from '@/fsd/shared/layouts/MainLayout';
    import { Notification } from '@/fsd/shared/ui';
    import { setOptions, bootstrap } from 'vue-gtag';
    import { mapState } from 'vuex';
    // import CookieBanner from './components/common/CookieBanner/CookieBanner.vue';

    export default {
        name: 'App',
        components: {
            Authorization,
            // MainLayout,
            Notification,
        },
        computed: {
            ...mapState(['isAuth', 'isInvoice']),
            ...mapState('CookieSettings', ['cookieSettings']),
        },
        methods: {
            async enableStatistic() {
                if (this.cookieSettings.statistics) {
                    setOptions({
                        config: { id: 'G-L69YRXL0RL' },
                    });
                    await bootstrap();
                }
            },
        },
    };
</script>

<style lang="scss">
    @import './scss/normalize.scss';
    @import './scss/fonts.scss';
    @import './scss/base-styles';
</style>
