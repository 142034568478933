export const useInputBase = ({ name, value }) => {
    const isResendDisabled = ref(false);

    const modelValue = computed({
        get() {
            return value.value;
        },
        set(value) {
            emits('update:modelValue', {
                name: name.value,
                value,
            });
        },
    });

    const clear = () => {
        modelValue.value = '';
    };

    const resend = () => {
        isResendDisabled.value = true;

        setTimeout(() => {
            isResendDisabled.value = false;
        }, 30000);

        emits('resend');
    };

    return {
        isResendDisabled,
        modelValue,
        clear,
        resend,
    };
};
