import GoogleEmpty from 'google-protobuf/google/protobuf/empty_pb.js';

export const useBaseApi = (
    { PromiseClient = null, Client = null },
    payload = { url: '', credentials: null, options: null }
) => {
    return {
        promiseClient: PromiseClient
            ? new PromiseClient(payload.url, payload.credentials, payload.options)
            : null,
        client: Client ? new Client(payload.url, payload.credentials, payload.options) : null,
        googleEmpty: new GoogleEmpty.Empty(),
    };
};
