import { ref, watch } from 'vue';

export const useButtonBaseLoader = (isLoading) => {
    const delay = ref(null);
    const isShowButtonLoader = ref(false);

    watch(isLoading, (value) => {
        if (delay.value) {
            clearTimeout(delay.value);
        }

        if (value) {
            delay.value = setTimeout(function () {
                isShowButtonLoader.value = true;
            }, 500);
        } else {
            isShowButtonLoader.value = false;
        }
    });

    return {
        isShowButtonLoader,
    };
};
