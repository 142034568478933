import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb';

export const getTimestampDate = (date) => Timestamp.fromDate(date);

export const getDateFromTimestamp = (timestamp) => {
    const instance = new Timestamp();

    instance.setSeconds(timestamp.seconds);
    instance.setNanos(timestamp.nanos);

    return instance.toDate();
};

export const getTimestamp = (timestamp) => {
    const instance = new Timestamp();

    instance.setSeconds(timestamp.seconds);
    instance.setNanos(timestamp.nanos);

    return instance;
};
