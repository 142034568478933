import { useBaseApi } from '../base';
import {
    AuthorizationPromiseClient as PromiseClient,
    AuthorizationClient as Client,
    RegistrationRequest,
    LoginRequest,
    ConfirmCodeRequest,
    InitResetPasswordRequest,
    ResendCodeRequest,
    VerifyLoginRequest,
    LogoutRequest,
    ResetPasswordRequest,
} from './proto';

export const useAuthorizationApi = () => {
    const { promiseClient, client } = useBaseApi(
        {
            PromiseClient,
            Client,
        },
        { url: process.env.API_URL }
    );

    const register = function ({ email, password }) {
        const request = new RegistrationRequest();

        request.setEmail(email);
        request.setPassword(password);

        return promiseClient.register(request, {});
    };

    const login = function ({ email, password }) {
        const request = new LoginRequest();

        request.setEmail(email);
        request.setPassword(password);

        return Promise.allSettled([
            new Promise((resolve, reject) => {
                client
                    .login(request, {}, (err, res) => {})
                    .on('metadata', (metadata) => {
                        if (metadata) resolve(metadata);
                        reject(metadata);
                    });
            }),
            promiseClient.login(request, {}),
        ]);
    };

    const confirmCode = function ({ userId, code }) {
        const request = new ConfirmCodeRequest();

        request.setUserid(userId);
        request.setCode(code);

        return promiseClient.confirmCode(request, {});
    };

    const sendConfirmCode = function ({ email }) {
        const request = new InitResetPasswordRequest();

        request.setEmail(email);

        return promiseClient.initResetPassword(request, {});
    };

    const resendConfirmCode = function ({ userId }) {
        const request = new ResendCodeRequest();

        request.setUserid(userId);

        return promiseClient.resendCode(request, {});
    };

    const changePassword = function ({ email, password, code }) {
        const request = new ResetPasswordRequest();

        request.setEmail(email);
        request.setNewpassword(password);
        request.setCode(code);

        return promiseClient.resetPassword(request, {});
    };

    const verifyLogin = function ({ session }) {
        const request = new VerifyLoginRequest();

        request.setSession(session);

        return promiseClient.verfyLogin(request, {});
    };

    const logout = function ({ session }) {
        const request = new LogoutRequest();

        request.setSessionid(session);

        return promiseClient.logout(request, {});
    };

    return {
        login,
        register,
        confirmCode,
        sendConfirmCode,
        resendConfirmCode,
        changePassword,
        verifyLogin,
        logout,
    };
};
