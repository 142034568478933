export const blockScroll = () => {
    const body = document.body;
    const scrollWidth = window.innerWidth - body.clientWidth;

    body.style.transition = 'margin-right 0.3s easy-in-out';
    body.style.marginRight = `${scrollWidth}px`;
    body.style.overflow = 'hidden';

    if (!window.matchMedia('(min-width: 1024px)').matches) {
        const offsetY = window.pageYOffset;

        body.style.top = `${-offsetY}px`;
        body.style.position = 'fixed';
        body.style.width = '100%';
    }
};

export const unblockScroll = () => {
    const body = document.body;

    body.style.transition = 'margin-right 0s easy-in-out';
    body.style.marginRight = '0';
    body.style.overflow = 'unset';

    if (!window.matchMedia('(min-width: 1024px)').matches) {
        const offsetY = Math.abs(parseInt(body.style.top || 0, 10));

        body.style.position = 'static';
        body.style.width = 'auto';
        body.style.removeProperty('top');
        window.scrollTo(0, offsetY || 0);
    }
};
