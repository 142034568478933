<template>
    <label class="input-base">
        <span
            v-if="!name"
            class="input-base__name"
        >
            {{ name }}
        </span>
        <span class="input-base__container">
            <span
                v-if="prefix"
                class="input-base__prefix"
            >
                {{ prefix }}
            </span>
            <input
                v-model="modelValue"
                :type="type"
                :placeholder="placeholder"
                class="input-base__input"
                :class="{
                    error: error,
                    prefix: prefix,
                }"
                :disabled="disabled"
            />
            <button
                v-show="isCanReseting && modelValue.length > 0"
                class="input-base__reset"
                type="button"
                @click="clear"
            >
                <icon :icon-name="clearIconName" />
            </button>
            <span
                v-if="error"
                class="input-base__error"
            >
                {{ error }}
            </span>
        </span>
        <router-link
            v-if="isHavePassword"
            to="/send-code"
            class="input-base__forgot"
        >
            Forgot password?
        </router-link>
        <button
            v-if="isCanReseting"
            type="button"
            :disabled="isResendDisabled"
            class="input-base__forgot"
            @click="resend"
        >
            Resend code
        </button>
    </label>
</template>

<script setup>
    import { defineEmits, toRefs } from 'vue';
    import { Icon } from '@/fsd/shared/ui';
    import { useInputBase } from './useCases';

    const emits = defineEmits(['update:modelValue', 'resend']);

    const props = defineProps({
        value: {
            type: String,
            default: '',
        },
        name: {
            type: String,
            default: '',
        },
        prefix: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: '',
        },
        error: {
            type: String,
            default: '',
        },
        clearIconName: {
            type: String,
            default: 'delete',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        isHavePassword: {
            type: Boolean,
            default: false,
        },
        isCanReseting: {
            type: Boolean,
            default: true,
        },
        isHaveResend: {
            type: Boolean,
            default: false,
        },
    });

    const { value, name } = toRefs(props);

    const { modelValue, isResendDisabled, resend, clear } = useInputBase({ name, value });
</script>

<style scoped lang="scss">
    @import './styles/input-base.scss';
</style>
