<template>
    <button
        class="button-base-authorization"
        :class="classes"
        :disabled="disabled"
        :type="type"
    >
        <icon
            v-if="iconName"
            :icon-name="iconName"
            class="button-base-authorization__icon"
        />
        <span class="button-base-authorization__name">{{ name }}</span>
    </button>
</template>

<script setup>
    import { computed, toRefs } from 'vue';
    import { Icon } from '@/fsd/shared/ui';
    import { useButtonBaseLoader } from './useCases';

    const props = defineProps({
        name: {
            type: String,
            default: '',
        },
        iconName: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: 'submit',
        },
    });

    const { isLoading } = toRefs(props);

    const { isShowButtonLoader } = useButtonBaseLoader(isLoading);

    const classes = computed(() => ({
        'button-base-authorization--loading': isShowButtonLoader.value,
    }));
</script>

<style scoped lang="scss">
    @import './styles/button-base.scss';
</style>
