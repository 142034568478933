<template>
    <div class="layout-inner">
        <main
            :class="[
                'layout-main',
                'layout-main--authorization',
                { 'layout-main--settings': $route.name === 'CookieSettings' },
            ]"
        >
            <router-view />
        </main>
    </div>
</template>

<script>
    import { mapState } from 'vuex';

    export default {
        name: 'AuthorizationLayout',
        computed: {
            ...mapState(['isAuth']),
        },
    };
</script>

<style lang="scss" scoped>
    @import './styles.scss';
</style>
